import React, {useState} from 'react';
import {useMutation} from 'react-query';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import FinancialServices from '../../components/Company/FinancialServices';
import KycServices from '../../components/Company/KycServices';
import GhanaKycServices from '../../components/Company/GhanaKycServices';
import KenyaKycServices from '../../components/Company/KenyaKycServices';
import Wallet from '../../components/Company/Wallet';
import BillPaymentServices from '../../components/Company/BillPaymentServices';
import GeneralServices from '../../components/Company/GeneralServices';
import MLServices from '../../components/Company/MLServices';
import MessagingServices from '../../components/Company/MessagingServices';
import CompanyLayout from './CompanyLayout';
import PrimaryButton from '../../components/PrimaryButton';
import GoToTop from '../../components/GoToTop';
import {updateCompanyPricing} from '../../helpers/queryFns/fetchCompanyDetails';
import AddressServices from '../../components/Company/AddressService';
import AngolaKycServices from '../../components/Company/AngolaKycServices';
import SouthAfricaIdServices from '../../components/Company/SouthAfricaKycServices';
import ZimbabweKycServices from '../../components/Company/ZimbabweKycServices';
import ZambiaKycServices from '../../components/Company/ZambiaKycServices';

function CompanyServices({auth: {user}}) {
  const {id} = useParams();

  const [companyObj, setCompanyObj] = useState({});

  const updateState = value => {
    setCompanyObj(original => ({
      ...original,
      ...value,
    }));
  };

  const {mutate, isLoading: updatingPricing} = useMutation(
    val => updateCompanyPricing(id, val),
    {
      onSuccess: res => {
        toast.success(res.data.message ?? 'Updated successfully!');
      },
    },
  );

  return (
    <DashboardLayout className="mt-4" headerText="Company/Services">
      <LayoutContainer>
        <CompanyLayout>
          <div className="grid grid-cols-1 mb-20 md:grid-cols-2 gap-x-8 lg:gap-x-16 w-fit">
            <div>
              <FinancialServices onUpdate={updateState} />
              <KycServices onUpdate={updateState} />
              <GhanaKycServices onUpdate={updateState} />
              <KenyaKycServices onUpdate={updateState} />
              <AngolaKycServices onUpdate={updateState} />
              <SouthAfricaIdServices onUpdate={updateState} />
              <ZimbabweKycServices onUpdate={updateState} />
              <ZambiaKycServices onUpdate={updateState} />
              <Wallet onUpdate={updateState} />
            </div>
            <div>
              <BillPaymentServices />
              <GeneralServices />
              <MLServices onUpdate={updateState} />
              <MessagingServices onUpdate={updateState} />
              <AddressServices onUpdate={updateState} />
              <PrimaryButton
                buttonText="Save changes"
                className={`block px-6 mt-8 ml-auto ${
                  user?.role?.includes('Retention') &&
                  !user?.role?.includes('Admin')
                    ? 'hidden'
                    : ''
                }`}
                loadingState={updatingPricing}
                disabled={Boolean(updatingPricing)}
                onClick={() => mutate(companyObj)}
              />
            </div>
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}

export default connect(state => state, null)(CompanyServices);
