export function getSettlementStatusClass(status) {
  if (!status) return 'bg-grey';

  switch (status?.toLowerCase()) {
    case 'failed':
      return 'bg-danger';
    case 'success':
    case 'completed':
      return 'bg-success';
    case 'ongoing':
      return 'bg-brandBlue';
    case 'pending':
      return 'bg-yellow';
    default:
      return 'bg-danger';
  }
}
